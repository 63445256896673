<template>
    <div id="carrieres" class="page-wrapper page-carrieres">
        <!-- SubHero -->
        <header class="sub-hero site-max-width" hero-transition-group data-gsap-change-color="default">
            <div class="container">
                <h1 class="small-title title" data-inview="fadeInUp" data-delay="100">Carrières</h1>
                <p class="big-text text" data-inview="fadeInUp" data-delay="200">
                    On a l’air chum? <br />
                    Deviens l’un des nôtres.
                </p>
                <img src="@/assets/imgs/carrieres-hero.png" alt="Carrières" class="main" data-inview="fadeInUp" data-delay="500" />
            </div>
        </header>

        <!-- Content -->
        <div
            id="zone-carrieres"
            class="carrieres carrieres-wrap no-border site-max-width"
            data-gsap-change-color="black"
            hero-transition-group
        >
            <div class="container" data-gsap-change-color="black">

                <div class="spacer"></div>

                <div class="carrieres-row" data-gsap-change-color="black">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Créativité</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <ul>
                            <li>
                                <a href="https://careers.smartrecruiters.com/Videotron/qolab" target="_blank" rel="noopener noreferrer">
                                    <span>Consulter les offres d’emploi</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24.538" viewBox="494 5204.89 41.527 38.538"><path d="m516.259 5204.89-2.16 2.16 15.56 15.558h-35.66v3.045h35.714l-15.614 15.614 2.16 2.16 19.268-19.269-19.268-19.268Z" fill-rule="evenodd" data-name="Path 58"/></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="carrieres-row" data-gsap-change-color="black">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Contenu</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <ul>
                            <li>
                                <a href="https://careers.smartrecruiters.com/Videotron/qolab" target="_blank" rel="noopener noreferrer">
                                    <span>Consulter les offres d’emploi</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24.538" viewBox="494 5204.89 41.527 38.538"><path d="m516.259 5204.89-2.16 2.16 15.56 15.558h-35.66v3.045h35.714l-15.614 15.614 2.16 2.16 19.268-19.269-19.268-19.268Z" fill-rule="evenodd" data-name="Path 58"/></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="productionJobListing.length" class="carrieres-row" data-gsap-change-color="black">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Production</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <ul>
                            <li>
                                <a href="https://careers.smartrecruiters.com/Videotron/qolab" target="_blank" rel="noopener noreferrer">
                                    <span>Consulter les offres d’emploi</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24.538" viewBox="494 5204.89 41.527 38.538"><path d="m516.259 5204.89-2.16 2.16 15.56 15.558h-35.66v3.045h35.714l-15.614 15.614 2.16 2.16 19.268-19.269-19.268-19.268Z" fill-rule="evenodd" data-name="Path 58"/></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer Nav -->
        <router-link :to="{ name: 'HomePage-fr' }" class="full-video footer-nav" data-gsap-activate-footer data-gsap-change-color="black">
            <div class="container">
                <img class="main" src="@/assets/imgs/carrieres-footer.png" alt="Candidature spontanée" />
                <div class="content">
                    <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                        Préparez-vous à votre entrevue, apprenez-en plus sur le bureau créatif.

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            viewBox="139 15409.606 95.627 88.742"
                        >
                            <path
                                d="m190.256 15409.607-4.973 4.973 35.829 35.828H139v7.012h82.239l-35.956 35.956 4.973 4.973 44.37-44.371-44.37-44.371Z"
                                fill="#ceffb4"
                                fill-rule="evenodd"
                                data-name="Path 471"
                            />
                        </svg>
                    </h2>
                    <!-- svg -->
                </div>
            </div>
        </router-link>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import GLightbox from 'glightbox'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(ScrollToPlugin)

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Carrieres',

    components: {
        Footer,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    document.querySelector('.main-nav').classList.remove('creativite', 'contenu', 'production')

                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        setTimeout(() => {
                            this.initGsap()
                            this.initGsapBgColors()

                            this.gsapActivateFooter()

                            gsap.utils.toArray('[data-gsap-parrallax-scroll]').forEach(element => {
                                gsap.to(element, {
                                    scrollTrigger: {
                                        trigger: element,
                                        start: 'top bottom',
                                        end: 'bottom top',
                                        scrub: 1.475,
                                    },
                                    y: '-25%',
                                    ease: 'none',
                                })
                            })

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {}
    },

    computed: {
        creativiteJobListing() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.carrieresEntry.data.filter(i => i.secteurSlug === 'creativite')
        },
        contenuJobListing() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.carrieresEntry.data.filter(i => i.secteurSlug === 'contenu')
        },
        productionJobListing() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.carrieresEntry.data.filter(i => i.secteurSlug === 'production')
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initGsap() {
            // gsap.utils.toArray('[data-gsap-scroll-left]').forEach(track => {
            //     gsap.to(track, {
            //         scrollTrigger: {
            //             trigger: track,
            //             start: 'top bottom',
            //             end: 'bottom top',
            //             scrub: 1.475,
            //         },
            //         x: '-25%',
            //         ease: 'none',
            //     })
            // })
        },
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 60%',
                    end: 'bottom 30%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-carrieres')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-carrieres').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-carrieres').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-carrieres')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-carrieres').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-carrieres').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-carrieres')) {
                        return;
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-carrieres')) {
                        return;
                    }
                    footerFold.classList.remove('is-active')
                },
            })
        }
    },
}
</script>
